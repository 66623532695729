import React from "react";

const Icon: React.FC = (props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.009 512.009">
      <g>
        <g>
          <g>
            <path d="M501.342,426.671h-10.667v-84.843l11.115-0.491c5.888-0.256,10.453-5.227,10.197-11.115
              c-0.256-5.888-4.971-10.219-11.093-10.219l-10.219,0.448V138.671c0-5.888-4.779-10.667-10.667-10.667
              c-5.888,0-10.667,4.779-10.667,10.667v10.667h-42.667v-10.667c0-5.888-4.779-10.667-10.667-10.667
              c-5.888,0-10.667,4.779-10.667,10.667v32c-17.003,34.304-36.971,41.451-69.717,42.667c-5.888,0.235-10.475,5.205-10.261,11.072
              c0.192,5.739,4.928,10.261,10.645,10.261h0.405c27.947-1.045,50.432-6.72,68.928-26.304v31.552
              c-20.053,12.864-40.917,18.752-62.144,16.725c-64.683-6.592-130.688-84.992-172.523-204.693v-41.28
              c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667v32h-64v-32c0-5.888-4.779-10.667-10.667-10.667
              S64.009,4.783,64.009,10.671v42.304c0,0,0,0,0,0.021v285.995l-53.781,2.347c-5.888,0.256-10.453,5.248-10.219,11.115
              c0.277,5.739,4.992,10.219,10.667,10.219h0.469l52.864-2.304v130.304H53.342c-5.888,0-10.667,4.779-10.667,10.667
              c0,5.888,4.779,10.667,10.667,10.667h128c5.888,0,10.667-4.779,10.667-10.667c0-5.888-4.779-10.667-10.667-10.667h-10.667
              V355.738l234.667-10.197v81.131h-10.667c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h106.667
              c5.888,0,10.667-4.779,10.667-10.667C512.009,431.45,507.23,426.671,501.342,426.671z M149.342,490.671h-64v-42.667h64V490.671z
              M149.342,426.671h-64V359.45l64-2.773V426.671z M149.342,335.279l-64,2.773V105.263c16.853,35.285,38.101,70.293,64,96.96
              V335.279z M149.342,170.287c-24.491-30.869-44.117-69.952-58.965-106.283h58.965V170.287z M405.342,324.164l-234.667,10.197
              V221.316c14.741,11.264,30.72,19.797,48.064,24.149c0.875,0.213,1.728,0.32,2.603,0.32c4.779,0,9.131-3.243,10.347-8.107
              c1.451-5.696-2.027-11.499-7.744-12.928c-19.499-4.885-37.227-16.107-53.269-31.189v-83.925
              c44.821,99.925,106.453,161.749,170.368,168.235c3.563,0.341,7.253,0.555,11.115,0.555c15.723,0,33.877-3.477,53.184-13.44
              V324.164z M426.675,173.828c0-0.021,0-0.064,0-0.085v-3.072h41.6c-13.184,21.525-27.093,39.253-41.6,52.779V173.828z
              M469.342,426.671h-42.667v-21.333h42.667V426.671z M469.342,384.004h-42.667v-39.403l42.667-1.856V384.004z M469.342,321.37
              l-42.667,1.856v-71.744c13.995-10.56,28.352-24.917,42.667-44.096V321.37z" fill="white" />
            <path d="M394.675,469.338c-5.888,0-10.667,4.779-10.667,10.667c0,4.352-8.32,10.667-21.333,10.667s-21.333-6.315-21.333-10.667
              c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667c0,4.352-8.32,10.667-21.333,10.667
              c-13.013,0-21.333-6.315-21.333-10.667c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667
              c0,17.941,18.731,32,42.667,32c12.949,0,24.235-4.203,32-10.816c7.744,6.613,19.051,10.816,32,10.816
              c23.936,0,42.667-14.059,42.667-32C405.342,474.116,400.563,469.338,394.675,469.338z" fill="white" />
          </g>
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default Icon;
